<script setup lang="ts">
const { t } = useI18n()

defineProps({
  src: {
    type: String,
    required: true,
  },
  image: {
    type: Object,
    required: true,
  },
})

const isActive = ref(false)
</script>

<template>
  <figure v-if="image" ref="frame" class="img-frame">
    <img :src="src" :alt="image.title" />
    <div
      v-if="image.title || image.license"
      :class="['caption-area', { active: isActive }]"
    >
      <IconButton
        class="caption-toggle"
        icon-class="bi-info-circle-fill"
        :button-title="t('image.showImageInfo')"
        @click.prevent="isActive = true"
      />
      <figcaption class="caption" @click.prevent="isActive = false">
        <p>
          <strong v-if="image.title">{{ image.title }}</strong>
        </p>
        <p v-if="image.author">{{ image.author }}</p>
        <template v-if="isActive">
          <p v-if="image.license">
            <a :href="image.license_url || '#'" target="_blank" @click.stop>{{
              image.license
            }}</a>
          </p>
        </template>
      </figcaption>
    </div>
  </figure>
</template>

<style scoped lang="scss">
.img-frame {
  position: relative;
  // overflow: hidden;
  width: 100%;
  height: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  a {
    text-decoration: underline;
  }
}
.caption-area {
  left: 10px;
  bottom: 10px;
  position: absolute;
  z-index: 15;
  font-size: 1.2em;
  .caption-toggle {
    padding: 0.5em;
    margin: -0.5em;
  }
  .caption {
    display: none;
    cursor: pointer;
  }
  &.active {
    .caption {
      display: block;
    }
    .caption-toggle {
      display: none;
    }
  }
}

.caption {
  text-align: left;
  @include panel-base;
  font-size: 0.8rem;
  padding: 0.2em 0.5em;
  max-width: 95%;
  &:focus-visible {
    @extend %focus-visual;
    outline-offset: -2px;
    outline-width: 2px;
  }

  p {
  }
}

.caption-toggle {
  color: rgb(255 255 255 / 70%);
  text-shadow: 0 0 0.25em rgb(0 0 0 / 10%);
  line-height: 1;
  display: block;

  &:focus-visible {
    color: var(--color-focus);
    outline-offset: 4px;
  }
}
</style>
